import "@lottiefiles/lottie-player";

import '../css/main.scss';

(function () {
  const getDropdownList = (element) => {
    return element.querySelector('.w-dropdown-list');
  };
  const openDropdown = (element) => {
    // w-dropdown-toggle-animation
    getDropdownList(element).classList.add('w--open')
    const animation = element.querySelector('.w-dropdown-toggle-animation');
    if (animation) {
      animation.setDirection(1);
      animation.play();
    }
  };
  const closeDropdown = (element) => {
    getDropdownList(element).classList.remove('w--open')
    const animation = element.querySelector('.w-dropdown-toggle-animation');
    if (animation) {
      animation.setDirection(-1);
      animation.play();
    }
  };
  const toggleDropdown = (element) => {
    const dropdownList = getDropdownList(element);
    dropdownList.classList.toggle('w--open')
    const animation = element.querySelector('.w-dropdown-toggle-animation');
    if (animation) {
      animation.setDirection(dropdownList.classList.contains('w--open') ? 1 : -1);
      animation.play();
    }
  };

  const toggles = document.querySelectorAll('.w-dropdown-toggle');

  Array.from(toggles).forEach((element) => {
    element.addEventListener('click', function () {
      toggleDropdown(element.parentNode);
    }, false)
  });

  const dropdowns = document.querySelectorAll('.w-dropdown');

  Array.from(dropdowns).forEach((element) => {
    if (element.getAttribute('data-hover') !== 'true') {
      return;
    }
    element.addEventListener('mouseenter', function () {
      openDropdown(element);
    }, false);
    element.addEventListener('mouseleave', function () {
      closeDropdown(element);
    }, false);
  });
})();
